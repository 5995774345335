<template>
  <div class="StatisticalOverview">
         <div class="StatisticalOverview_nav">
               <span>选择项目:</span>
               <el-select v-model="pId" placeholder="请选择" :disabled="disabled1"> 
                          <el-option
                            v-for="(item,index) in projectlist"
                            :key="index"
                            :label="item.TopicName"
                            :value="item.Id">
                          </el-option>
               </el-select>
               <span style="margin-left: 20px;">选择课题:</span>
               <el-select v-model="tId" placeholder="请选择" :disabled="disabled2" @change="topiclistchange">
                          <el-option
                            v-for="(item,index)  in topiclist"
                            :key="index"
                            :label="item.TopicName"
                            :value="item.Id">
                          </el-option>
               </el-select>
               <span style="margin-left: 20px;">选择子课题:</span>
               <el-select v-model="sId" placeholder="请选择" :disabled="disabled3" @change="Subtopicchange">
                          <el-option
                            v-for="(item,index) in subtopic"
                            :key="index"
                            :label="item.TopicName"
                            :value="item.Id">
                          </el-option>
               </el-select>
                <el-button type="primary" style="margin-left: 20px;" @click="Reset">重置</el-button>
         </div>
         <div class="StatisticalOverview_middle">
            <div class="StatisticalOverview_left">
                <div class="StatisticalOverview_left1">
                  <h2>
                      <p>
                          <span></span>
                          <span>项目概览</span>
                      </p>
                  </h2>
                  <!-- <div class="StatisticalOverview_left1_head">
                      <span>选择项目:</span>
                      <el-select v-model="value" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      <span>选择课题:</span>
                      <el-select v-model="value" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                  </div> -->
                  <div class="StatisticalOverview_left1_table">
                      <el-table
                        :data="plisttableData"
                        height="328px"
                        style="width: 100%">
                        <el-table-column
                          align="center"
                          label="类型">
                              <template slot-scope="scope">
                            <el-tag style="background: #3388ff;border-color:#fff;" effect="dark" type='success' v-show="scope.row.TopicClass==1?true:false">项目</el-tag>
                               <el-tag style="background: #319EFD;border-color:#fff;" effect="dark" type='info' v-show="scope.row.TopicClass==2?true:false">课题</el-tag>
                               <el-tag style="background: #FF718E;border-color:#fff;" effect="dark" type='danger' v-show="scope.row.TopicClass==3?true:false">子课题</el-tag>
                             </template>
                         </el-table-column> 
                        <el-table-column
                          align="center"
                          label="名称"
                          width="380">
                            <template slot-scope="scope">
                               <p style="cursor:pointer;" @click="projectclick(scope.row.Id)" >{{scope.row.TopicName}}</p>
                             </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="任务进度"
                          width="200">
                                <template slot-scope="scope">
                                  <el-progress :percentage="scope.row.Num" :stroke-width="12" style="width: 140px;margin-bottom: 6px;margin-left: 20px;"></el-progress>
                                </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          prop="FlowName"
                          label="所处阶段">
                        </el-table-column>
                       <el-table-column
                          align="center"
                          label="成果进度">
                                <template slot-scope="scope">
                                  <p style="color:#333333;">{{scope.row.ArchivesNum}}个</p>
                                </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          label="采集数">
                                 <template slot-scope="scope">
                                  <p style="color:#333333;">{{scope.row.CollectNumber}}条</p>
                                </template>
                        </el-table-column>
                      </el-table>
                  </div>
                </div>
                <div class="StatisticalOverview_left2">
                  <div class="StatisticalOverview_left2_1">
                      <h2>
                        <p class="p1">
                          <span></span>
                          <span>拨款进度</span>
                       </p>
                        <!-- <p class="p2">
                          <el-dropdown>
                              <el-button type="text">选择项目<i class="el-icon-caret-bottom"></i></el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>黄金糕</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                        </p> -->
                      </h2>
                      <div class="StatisticalOverview_ul"  @scroll="Scroll">
                            <div class="StatisticalOverview_li" v-for="(item,index) in appropriation" :key="index">
                              <p>
                               <el-tag style="color: #3978E7;background: #EEF2FF;border-color:#fff;" type='success' v-show="item.TopicClass==1?true:false">项目</el-tag>
                               <el-tag style="color: #0095FF;background: #EAF6FF;border-color:#fff;" type='info' v-show="item.TopicClass==2?true:false">课题</el-tag>
                               <el-tag style="color: #FA5A8E;background: #FFEFF3;border-color:#fff;"  type='danger' v-show="item.TopicClass==3?true:false">子课题</el-tag>
                                  <el-tooltip class="item" effect="dark"  placement="top">
                                    <div slot="content">
                                    <p style="font-size: 16px;">
                                        {{item.TopicName}}
                                     </p>
                                    </div>
                                      <span style="cursor:pointer;" @click="Appropriationclick(item.Id)">{{item.TopicName}}</span>
                                  </el-tooltip>
                              </p>
                              <ul class="ul1">
                                <li>
                                    <h3>{{item.TopicFinanc}}万元</h3>
                                    <p>预算总额</p>
                                </li>
                                <li style="margin-right: 10px;">
                                    <h3 style="color:#53C8D1;">{{item.AmountReceivedSum}}万元</h3>
                                    <p>已拨款总额</p>
                                </li>
                                <li style="margin-right: 16px;">
                                    <el-progress :percentage="item.TopicProgress" :stroke-width="12" style="width: 140px;margin-bottom: 6px;"></el-progress>
                                    <p>进度占比</p>
                                </li>
                                <li @click="detailsshow(item.Id,item.flag)">
                                    <el-button type="text">详情<i :class="item.flag==true?'el-icon-arrow-up':'el-icon-arrow-down'"></i></el-button>
                                </li>
                              </ul>
                               <el-collapse-transition>
                              <div class="StatisticalOverview_show" v-show="item.flag">
                                  <el-timeline>
                                    <el-timeline-item
                                      v-for="(activity, index) in item.children"
                                      :key="index"
                                      :timestamp="activity.ArrivalDate">
                                     <span class="span1">已拨款{{activity.AmountReceived}}万元</span><span class="span2">{{activity.HospitalName}}</span>
                                    </el-timeline-item>
                                  </el-timeline>
                                  <p @click="ViewG(item.Id)" style="cursor:pointer;" v-show="appropriationshow.length==total?false:true">
                                     查看更多
                                  </p>
                                  <p v-show="appropriationshow.length!=total||item.children.length==0?false:true">
                                     没有更多东西了...
                                  </p>
                              </div>
                              </el-collapse-transition>
                              <el-collapse-transition>
                               <div class="StatisticalOverview_show1" v-show="item.children.length==0&&item.flag==true?true:false">
                                    暂无数据
                               </div>
                                </el-collapse-transition>
                            </div>
                            <p v-show="loading">加载中...</p>
                      </div>
                  </div>
                  <div class="StatisticalOverview_left2_2">
                      <h2>
                        <p class="p1">
                          <span></span>
                          <span>病例采集数</span>
                        </p>
                        <!-- <p class="p2">
                            <el-dropdown>
                              <el-button type="text">选择项目<i class="el-icon-caret-bottom"></i></el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>黄金糕</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                            <el-dropdown>
                              <el-button type="text">选择课题<i class="el-icon-caret-bottom"></i></el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>黄金糕</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                        </p> -->
                      </h2>
                      <h3>
                        <p class="h3_p1">已采集数（条）</p>
                        <p class="h3_p2">{{this.Collectedcase}}</p>
                      </h3>
                      <div class="StatisticalOverview_left2_table">
                        <el-table
                        :data="caselist"
                        height="228px"
                        style="width: 100%">
                        <el-table-column
                          align="center"
                          label="名称"
                          width="280">
                                <template slot-scope="scope">
                                  <p style="cursor:pointer;" @click="Casecollectionclick(scope.row.Id)">{{scope.row.TopicName}}</p>
                                </template>
                        </el-table-column>
                        <el-table-column
                          align="center"
                          prop="SerialNumber"
                          label="序号"
                          width="120">
                        </el-table-column>
                        <el-table-column
                          align="center"
                          prop="CollectNumber"
                          label="采集数">
                        </el-table-column>
                        </el-table>
                      </div>
                  </div>
                </div>
            </div>
            <div class="StatisticalOverview_right">
                    <div class="StatisticalOverview_right_1">
                      <h2>
                          <p>
                              <span></span>
                              <span>成果进度</span>
                          </p>
                      </h2>
                      <!-- <div class="StatisticalOverview_right1_head">
                      <span>选择项目:</span>
                      <el-select v-model="value" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      <span>选择课题:</span>
                      <el-select v-model="value" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div> -->
                      <div class="StatisticalOverview_right1_echarts">
                            <h3 id="main" style="width:100%;height:340px;"></h3>
                      </div>
                    </div>
                    <div class="StatisticalOverview_right_2">
                        <h2>
                          <p class="right_p1">
                              <span></span>
                              <span>经费支出概览</span>
                          </p>
                          <!-- <p class="right_p2">
                              <el-dropdown>
                                <el-button type="text">选择项目<i class="el-icon-caret-bottom"></i></el-button>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item>黄金糕</el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                          </p> -->
                        </h2>
                        <div class="StatisticalOverview_right_2_table">
                            <el-table
                                :data="fundslist"
                                style="width: 100%;"
                                height="278px"
                                row-key="Id"
                                default-expand-all
                                :load="load"
                                lazy
                                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                                :header-cell-style="{'text-align':'center'}" >
                                <el-table-column
                                  label="名称"
                                  width="180">
                                       <template slot-scope="scope">
                                      <span style="cursor:pointer;" @click="fundsclick(scope.row.Id)">{{scope.row.TopicName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                  align="center"
                                  prop="SerialNumber"
                                  label="序号"
                                  width="120">
                                </el-table-column>
                                <el-table-column
                                  align="center"
                                  prop="address"
                                  label="预算总额/支出总额">
                                  <template slot-scope="scope">
                                       <span style="color:#3388FF;margin-right: 4px;">{{scope.row.BudgetSum}}万元</span>/<span style="color:#FF7893;margin-left: 4px;">{{scope.row.PayoutMoneySum}}万元</span>
                                  </template>
                                </el-table-column>
                              </el-table>
                        </div>
                    </div>
            </div>
         </div>
  </div>
</template>
<script>
import api from "../../../api/Grantsandmilestones"
import apis from "../../../api/url.js";
import * as echarts from "echarts";
import {mapState} from "vuex";
export default {
  data(){
    return {
       total:0,
       listQuery: {
              pageNum: 1,
              pageSize: 2,
        },
       total1:0,
       listQuery1: {
              pageNum1: 1,
              pageSize1: 4,
        },
        pId:"",
        tId:-100,
        sId:-100,
        icon:"el-icon-arrow-up",
        show:false,
        disabled1:false,
        disabled2:false,
        disabled3:false,
        value: '',
        echartsobj:{},//成果进度数据
        namelist:['论文', '专利', '指南/共识', '总结/报告', '原始数据', '其他'],//成果进度名称
        plisttableData:[],//项目概览数据
        projectlist:[],//项目下拉框数据
        topiclist:[{TopicName:"全部",Id:-100}],//课题下拉框数据
        subtopic:[{TopicName:"全部",Id:-100}],//子课题下拉框数据
        projectIdCurrent:"",
        appropriation:[],//拨款进度数组
        appropriationshow:[],//拨款进度点击详情数组
        caselist:[],//病例采集数数组
        Collectedcase:"",//已采集个数
        fundslist:[],//经费支出数组
        Id:"",
        varname:"",
        flag:false,
        loading:false,
        TotalPages:1,
    }
  },
  computed: mapState(["projectId"]),
  created(){
    console.log(this.projectId);
    this.projectIdCurrent=this.projectId
    this.ProjectOverviewlist()
    this.projectsingle()
    this.Appropriationprogresslist()
    this.Numberofcasescollectedlist()
    this.Expenditurelist()
  },
  mounted(){
       this.achievementslist()
  },
  methods:{
      Scroll(e){
        // console.log(this.$refs.evabox.scrollTop) 
        const ele = e.srcElement ? e.srcElement : e.target
         // 监听滚动到div底部
        if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight-1) { 
                 if(this.flag==false){
                      this.flag=true
                      this.loading=true
                      this.listQuery1.pageNum1++
                      this.Appropriationprogresslist()
                 }
           }
    },
    fundsclick(Id){//经费支出概览
      this.$store.commit("menustate", 4);
      this.$store.commit("SaveId", Id);
      this.$store.commit("treedata", Id); // 保存树节点Id
      this.$store.commit("pmiddata", Id)
      this.$store.commit("nodekeydata",Id);     
      this.$router.push({name:"ChartOfAccounts"})
    },
    Casecollectionclick(Id){//病例采集数
      this.$store.commit("menustate", 5);
      this.$store.commit("SaveId", Id);
      this.$store.commit("treedata", Id); // 保存树节点Id
      this.$store.commit("pmiddata", Id)
      this.$store.commit("nodekeydata",Id);     
      this.$router.push({name:"RawData"})
    },
    Appropriationclick(Id){//拨款进度点击
      this.$store.commit("menustate", 4);
      this.$store.commit("SaveId", Id);
      this.$store.commit("treedata", Id); // 保存树节点Id
      this.$store.commit("pmiddata", Id)
      this.$store.commit("nodekeydata",Id);     
      this.$router.push({name:"Appropriationprogress"})
    },
    projectclick(Id){
      this.$store.commit("menustate", 3);
      this.$store.commit("SaveId", Id);
      this.$store.commit("treedata", Id); // 保存树节点Id
      this.$store.commit("pmiddata", Id)
      this.$store.commit("nodekeydata",Id);     
      this.$router.push({name:"ProjectInformation"})
    },
    Reset(){//重置
       this.appropriation=[]
       this.total1=0
       this.listQuery1.pageNum1=1
       if(this.disabled1&&this.disabled2==false&&this.disabled3==false){
            this.subtopic=[{TopicName:"全部",Id:-100}]//子课题下拉框数据
            this.tId=-100
            this.sId=-100
            this.projectIdCurrent=this.projectId
            this.ProjectOverviewlist()
            this.achievementslist()
            this.Appropriationprogresslist()
            this.Numberofcasescollectedlist()
            this.Expenditurelist()
            return
       }
       if(this.disabled1&&this.disabled2&&this.disabled3==false){
            this.sId=-100
            this.projectIdCurrent=this.projectId
            this.ProjectOverviewlist()
            this.achievementslist()
            this.Appropriationprogresslist()
            this.Numberofcasescollectedlist()
            this.Expenditurelist()
       }
    },
    ViewG(TopicId){//查看更多
      this.listQuery.pageNum++
      this.pagingmilepostarr(TopicId)
    },
    topiclistchange(){//课题选中值发生变化时触发
       this.appropriation=[]
       this.total1=0
       this.listQuery1.pageNum1=1
       this.subtopic=[{TopicName:"全部",Id:-100}]//子课题下拉框数据
      if(this.tId==-100){
          this.sId=-100
          this.projectIdCurrent=this.projectId
          this.ProjectOverviewlist()
          this.achievementslist()
          this.Appropriationprogresslist()
          this.Numberofcasescollectedlist()
          this.Expenditurelist()
      }else{
        this.sId=-100
        apis.topicallData(this.tId).then(res=>{
          if(res.data.Status==1){
            res.data.Entity.forEach(item=>{
                this.subtopic.push(item) 
            })
          }else{
            alert(res.data.Message)
          }
        })
        this.projectIdCurrent=this.tId
        this.ProjectOverviewlist()
        this.achievementslist()
        this.Appropriationprogresslist()
        this.Numberofcasescollectedlist()
        this.Expenditurelist()
      }
    },
    Subtopicchange(){//子课题选中值发生变化时触发
       this.appropriation=[]
       this.total1=0
       this.listQuery1.pageNum1=1
    if(this.sId==-100){
      this.projectIdCurrent=this.tId
      this.ProjectOverviewlist()
      this.achievementslist()
      this.Appropriationprogresslist()
      this.Numberofcasescollectedlist()
      this.Expenditurelist()
    }else{
      this.projectIdCurrent=this.sId
      this.ProjectOverviewlist()
      this.achievementslist()
      this.Appropriationprogresslist()
      this.Numberofcasescollectedlist()
      this.Expenditurelist()
    }
    },
    projectsingle(){//下拉框单个项目
          //子课题的pid可以查课题，子课题的projectId可以查项目
          apis.singletopicData(this.projectId).then(res=>{
               if(res.data.Status==1){
                   console.log(res,"数据");
                   if(res.data.ExtraData.TopicClass==1){//项目
                       this.projectlist.push(res.data.ExtraData)
                       this.pId=Number(this.projectId)
                       this.disabled1=true
                       apis.topicallData(res.data.ExtraData.Id).then(res=>{//查课题
                         console.log(res,"课题");
                         if(res.data.Status==1){
                            res.data.Entity.forEach(item=>{
                                this.topiclist.push(item)
                            })
                         }else{
                           alert(res.data.Message)
                         }
                       })
                       return
                   }
                   if(res.data.ExtraData.TopicClass==2){//课题
                       this.topiclist.push(res.data.ExtraData)
                       this.tId=Number(res.data.ExtraData.Id)
                       this.disabled1=true
                       this.disabled2=true
                       apis.topicallData(this.tId).then(res=>{//查子课题
                              console.log(res,"/查子课题");
                              if(res.data.Status==1){
                                 res.data.Entity.forEach(item=>{
                                    this.subtopic.push(item)
                                 })
                              }else{
                                alert(res.data.Message)
                              }     
                        })
                       apis.singletopicData(res.data.ExtraData.Pid).then(res=>{//查项目
                            console.log(res,"课题查项目");
                            if(res.data.Status==1){
                                this.projectlist.push(res.data.ExtraData)
                                this.pId=Number(res.data.ExtraData.Id)
                            }else{
                              alert(res.data.Message)
                            }
                       })
                       return
                   }
                   if(res.data.ExtraData.TopicClass==3){
                       console.log(res,"子课题");
                       this.disabled1=true
                       this.disabled2=true
                       this.disabled3=true
                       this.subtopic.push(res.data.ExtraData)//子课题
                       this.sId=Number(res.data.ExtraData.Id)
                       apis.singletopicData(res.data.ExtraData.Pid).then(res=>{//查课题
                          console.log(res,"查课题");
                          if(res.data.Status==1){
                               this.topiclist.push(res.data.ExtraData)
                               this.tId=res.data.ExtraData.Id
                          }else{
                               alert(res.data.Message)
                          }
                       })
                       apis.singletopicData(res.data.ExtraData.ProjectId).then(res=>{//查项目
                          console.log(res,"查项目");
                          if(res.data.Status==1){
                               this.projectlist.push(res.data.ExtraData)
                               this.pId=res.data.ExtraData.Id
                          }else{
                               alert(res.data.Message)
                          }
                       })
                   }
               }else{
                  alert(res.data.Message)
               }
          })
        //  api.projectallData().then(res=>{
        //    console.log(res,"项目");
        //    if(res.data.Status==1){
        //      this.projectlist=res.data.Entity
        //      this.pId=Number(this.projectId)
        //      console.log(this.$route.path);
        //      if(this.$route.path=="/StatisticalOverview"){//判断当前是否在项目管理下面的统计概览页面,如果在就加载下拉框课题
        //         apis.topicallData(this.projectId).then(res=>{
        //              console.log(res,"课题");
        //              if(res.data.Status==1){
        //                 this.topiclist=res.data.Entity
        //              }else{
        //                 alert(res.data.Message)
        //              }
        //         })
        //      }else{
        //          console.log(1111);
        //      }
        //    }else{
        //      alert(res.data.Message)
        //    }
        //  })
    },
    detailsshow(TopicId,flag){//点开查看详情
     console.log(flag);
     this.appropriationshow=[]
     this.listQuery.pageNum=1
     if(flag==false){
       this.pagingmilepostarr(TopicId)
       this.appropriation=this.appropriation.map(item=>{
           if(item.Id==TopicId){
              this.$set(item,"flag",true)
           }
           return item
       })
     }else{        
       this.appropriation=this.appropriation.map(item=>{
           if(item.Id==TopicId){
              this.$set(item,"flag",false)
           }
           return item
       })
     }
    },
    achievementsecharts(){
       var chartDom = document.getElementById('main');
       var myChart = echarts.init(chartDom);
       var option;
        option = {
          // dataZoom:{
          //     realtime:true, //拖动滚动条时是否动态的更新图表数据
          //     height:15,//滚动条高度
          //     start:0,//滚动条开始位置（共100等份）
          //     end:90//结束位置（共100等份）
          // },  
          xAxis: {
            name:"名称",
            type: 'category',
            data: this.namelist,
            nameTextStyle:{
               color:"#69696A",
            },
            axisLabel:{
                interval:0,
                inside:false,
                textStyle:{
                  color:"#69696A",
                  fontSize:"14",
                },
                color:"red",
            },
            axisTick:{
              show:false,
            },
            axisLine:{
              show:false,
            }
          },
          yAxis: {
            name:"数量",
            type: 'value',
            nameTextStyle:{
               color:"#69696A",
               padding:[0,0,10,-40]
            },
            axisLabel:{
                inside:false,
                textStyle:{
                  color:"#69696A",
                  fontSize:"14",
                }
            },
            axisTick:{
              show:false,
            },
            axisLine:{
              show:false,
            }
            
          },
          series: [
            {
              data: [this.echartsobj.thesis,this.echartsobj.patent,this.echartsobj.handbook,this.echartsobj.report,this.echartsobj.data,this.echartsobj.other],
              type: 'bar',
              barWidth:33,
              itemStyle:{
              normal:{
                color:function(params){
                  let colorlist=[//每跟颜色顺序
                    "#5B8FF9",
                    "#45EDF2",
                    "#FF7893",
                    "#FFB907",
                    "#AF7DE6",
                    "#96E78C",
                  ]
                  return colorlist[params.dataIndex]
                },
                label:{
                   show:true,//开启显示
                   position:"top",//在上方显示
                   textStyle:{//数值的样式
                     color:"#69696A",
                     fontSize:"14",
                   }
                }
              }
            }
            },
          ]
        };

       option && myChart.setOption(option);
       myChart.on('click', (parameter)=> {
         if(parameter.color=="#5B8FF9"){
            this.varname="Paper"
         }
         if(parameter.color=="#45EDF2"){
            this.varname="Patent"
         }
         if(parameter.color=="#FF7893"){
            this.varname="GuideExpert"
         }
         if(parameter.color=="#FFB907"){
            this.varname="SummaryReport"
         }
         if(parameter.color=="#AF7DE6"){
            this.varname="RawData"
         }
         if(parameter.color=="#96E78C"){
            this.varname="OtherFile"
         }
         
          if(this.pId!=""&&this.tId==-100&&this.sId==-100){
            console.log(this.pId);
              this.Id=this.pId
              this.$store.commit("menustate", 5);
              this.$store.commit("SaveId", this.Id);
              this.$store.commit("treedata", this.Id); // 保存树节点Id
              this.$store.commit("pmiddata", this.Id)
              this.$store.commit("nodekeydata",this.Id);     
              this.$router.push({name:this.varname})
              return
          }
          if(this.pId!=""&&this.tId!=-100&&this.sId==-100){
              this.Id=this.tId
              this.$store.commit("menustate", 5);
              this.$store.commit("SaveId", this.Id);
              this.$store.commit("treedata", this.Id); // 保存树节点Id
              this.$store.commit("pmiddata", this.Id)
              this.$store.commit("nodekeydata",this.Id);     
              this.$router.push({name:this.varname})
              return
          }
          if(this.pId!=""&&this.tId!=-100&&this.sId!=-100){
              this.Id=this.sId
              this.$store.commit("menustate", 5);
              this.$store.commit("SaveId", this.Id);
              this.$store.commit("treedata", this.Id); // 保存树节点Id
              this.$store.commit("pmiddata", this.Id)
              this.$store.commit("nodekeydata",this.Id);     
              this.$router.push({name:this.varname})
          }
          
       })
    },
    achievementslist(){//成果进度
      let parameter=this.projectIdCurrent
       api.achievementsData(parameter).then(res=>{
         if(res.data.Status==1){
            this.echartsobj=res.data.Entity
            this.achievementsecharts()
            this.$nextTick(() => {
               this.achievementsecharts()
            });
         }else{
           alert(res.data.Message)
         }
         console.log(res);
       })
    },
    load(tree, treeNode, resolve) {
      console.log(tree,treeNode);
      api.ExpenditureData(tree.Id).then(res=>{
        console.log(res,"值");
          if(res.data.Status==1){
             res.data.Entity.forEach(item=>{
               if(item.TopicClass!=3){
                 item.hasChildren=true
               }
             })
             resolve(res.data.Entity)
          }else{
             alert(res.data.Message)
          }
      })
    },
    ProjectOverviewlist(){//项目概览数据
       let parameter=this.projectIdCurrent
      api.ProjectOverviewData(parameter).then(res=>{
         console.log(res);
           if(res.data.Status==1){
             this.plisttableData=res.data.Entity
           }else{
             alert(res.data.Message)
           }
      })
    },
    Appropriationprogresslist(){//拨款进度
       if(this.listQuery1.pageNum1<=this.TotalPages){
         let parameter={
                TopicId:this.projectIdCurrent,
                key:"",
                PageIndex: this.listQuery1.pageNum1*1-1*1,
                PageSize: this.listQuery1.pageSize1,
         }
        api.AppropriationprogressData(parameter).then(res=>{
            console.log(res,"拨款进度");
            if(res.data.Status==1){
              res.data.Entity.forEach(item=>{
                   this.$set(item,"flag",false)
                   this.$set(item,"children",[])
              })
              this.appropriation=this.appropriation.concat(res.data.Entity)
              this.flag=false
              this.loading=false
              this.TotalPages=res.data.TotalPages
              this.total1=this.TotalRowsCount
            }else{
              alert(res.data.Message)
            }
        })
       }else{
          this.flag=false
          this.loading=false
        //   this.$message({
        //   message: "没有更多内容了",
        //   type: "success",
        // });
         console.log(222222);
       }
    },
    pagingmilepostarr(TopicId){
          let parame={
              TopicId:TopicId,
              key:"",
              PageIndex: this.listQuery.pageNum*1-1*1,
              PageSize: this.listQuery.pageSize,
           }
          api.pagingmilepostData(parame).then(res=>{
              if(res.data.Status==1){
                 this.total=res.data.TotalRowsCount
                 console.log(this.total,"条目");
                 res.data.Entity.forEach(item=>{
                         item.ArrivalDate=item.ArrivalDate.substr(0,10)
                 })
                 this.appropriationshow=this.appropriationshow.concat(res.data.Entity)
                 console.log(this.appropriationshow,"@@@@@@@@@");
                 this.appropriation=this.appropriation.map(item=>{
                   if(item.Id==TopicId){                      
                      this.$set(item,"children",this.appropriationshow)
                   }else{
                     this.$set(item,"children",[])
                   }
                   return item
                 })
              }else{
                 alert(res.data.Message)
              }
          })
    },
    Numberofcasescollectedlist(){//病例采集数
          let parame=this.projectIdCurrent
          api.NumberofcasescollectedData(parame).then(res=>{
               console.log(res,"病例采集数");
               if(res.data.Status==1){
                  this.Collectedcase=res.data.Entity.CollectNumberSum
                  this.caselist=res.data.Entity.TopicDataCollectNumberSumLists
               }
          })
    },
    Expenditurelist(){//经费支出概览
      let parame=this.projectIdCurrent
      api.ExpenditureData(parame).then(res=>{
          console.log(res,"经费支出概览");
          if(res.data.Status==1){
            this.fundslist=res.data.Entity
            this.fundslist.forEach(item=>{
              if(item.TopicClass!=3){
                item.hasChildren=true
              }
            })
          }else{
            alert(res.data.Message)
          }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.StatisticalOverview{
  width: 100%;
  height: 100%;
  // background: brown;
  padding-top: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .StatisticalOverview_nav{
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    span{
      margin-right: 10px;
      @include add-size($font_size_16);
      color: #666666;
    }
  }
  .StatisticalOverview_middle{
    width: 100%;
    height: 750px;
    display: flex;
    .StatisticalOverview_left{
      width: 65%;
      height: 750px;
      margin-right: 20px;
      .StatisticalOverview_left1{
        width: 100%;
        height: 390px;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 20px;
            h2 {
            width: 100%;
            height: 52px;
            // background: olive;
            overflow: hidden;
            margin-bottom: 10px;
            p {
              margin-top: 12px;
              span:nth-child(1) {
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #3388ff;
                margin-left: 21px;
                margin-right: 14px;
              }
              span:nth-child(2) {
                @include add-size1($font_size_20);
                font-weight: 500;
                color: #333333;
              }
            }
          }
          .StatisticalOverview_left1_head{
            margin-bottom: 20px;
            span{
              margin-left: 40px;
              margin-right: 6px;
            }
          }
          .StatisticalOverview_left1_table{
            width: 94%;
            margin: 0 auto;
            height: 328px;
          }
      }
      .StatisticalOverview_left2{
        width: 100%;
        height: 340px;
        border-radius: 6px;
        display: flex;
        .StatisticalOverview_left2_1{
          width: 49%;
          height: 340px;
          margin-right: 20px;
          background: #fff;
          border-radius: 6px;
           h2 {
            width: 100%;
            height: 52px;
            overflow: hidden;
            margin-bottom: 10px;
            .p1 {
              float: left;
              margin-top: 12px;
              span:nth-child(1) {
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #3388ff;
                margin-left: 21px;
                margin-right: 14px;
              }
              span:nth-child(2) {
                @include add-size1($font_size_20);
                font-weight: 500;
                color: #333333;
              }
            }
            .p2{
              margin-right: 20px;
              margin-top: 10px;
              float: right;
              .el-button{
                @include add-size($font_size_16);
                color: #585858;
              }
            }
          }
          .StatisticalOverview_ul{
             width: 100%;
             height: 278px;
             overflow:auto;
             .StatisticalOverview_li{
                 p{
                   text-overflow: ellipsis;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                   margin-bottom: 10px;
                .el-tag{
                 color: #319EFD;
                 @include add-size5($font_size_14);
                 margin-left: 20px;
                 margin-right: 6px;
               }
               span{
                 @include add-size4($font_size_18);
                 color: #585858;
                 font-weight: 550;
               }
                 }
                 .ul1{
                   display: flex;
                   margin-bottom: 14px;
                   li{
                     margin-left: 40px;
                       h3{
                         color: #319EFD;
                         font-weight: 400;
                         @include add-size($font_size_16);
                       }
                       p{
                         color: #666666;
                         @include add-size5($font_size_14);
                       }
                       .el-button{
                          color: #319EFD;
                          @include add-size($font_size_16);
                       }
                   }
                 }
                 .StatisticalOverview_show{
                   margin-left: 50px;
                   .span1{
                      color: #585858;
                      @include add-size5($font_size_14);
                   }
                   .span2{
                      margin-left: 8px;
                      color: #585858;
                      @include add-size5($font_size_14);  
                   }
                 }
                  .StatisticalOverview_show1{
                   margin-left: 50px;
                   text-align: center;
                   width:80%;
                   border-radius: 4px;
                   border: 1px solid #999;
                   margin-bottom: 10px;
                 }
             }
          }
        }
        .StatisticalOverview_left2_2{
          width: 49%;
          height: 340px;
          background: #fff;
          h2{
            width: 100%;
            height: 52px;
            overflow: hidden;
            margin-bottom: 10px;
            .p1 {
              margin-top: 12px;
              float: left;
              span:nth-child(1) {
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #3388ff;
                margin-left: 21px;
                margin-right: 14px;
              }
              span:nth-child(2) {
                @include add-size1($font_size_20);
                font-weight: 500;
                color: #333333;
              }
            }
            .p2{
              margin-right: 10px;
              margin-top: 10px;
              float: right;
              .el-button{
                @include add-size($font_size_16);
                color: #585858;
                margin-right: 14px;
              }
            }
          }
          h3{
            margin: 0 auto;
            width: 94%;
            height: 40px;
            background: #F0F7FD;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items:center;
            margin-bottom: 10px;
            .h3_p1{
              font-weight: 400;
              color: #6394F9;
              @include add-size($font_size_16);
              margin-left: 10px;
            }
            .h3_p2{
              font-weight: 600;
              color: #6394F9;
              @include add-size($font_size_16);
              margin-right: 10px;
            }
          }
          .StatisticalOverview_left2_table{
              width: 94%;
              margin: 0 auto;
          }
        }
      }
    }
    .StatisticalOverview_right{
      width: 34%;
      height: 760px;
      .StatisticalOverview_right_1{
         width: 100%;
         height: 390px;
         background: #fff;
         margin-bottom: 20px;
         border-radius: 6px;
         h2 {
            width: 100%;
            height: 52px;
            margin-bottom: 10px;
            overflow: hidden;
            p {
              margin-top: 12px;
              span:nth-child(1) {
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #3388ff;
                margin-left: 21px;
                margin-right: 14px;
              }
              span:nth-child(2) {
                @include add-size1($font_size_20);
                font-weight: 500;
                color: #333333;
              }
            }
          }
          .StatisticalOverview_right1_head{
              span{
                margin-left: 26px;
                margin-right: 6px;
              }
          }
      }
      .StatisticalOverview_right_2{
         width: 100%;
         height: 340px;
         border-radius: 6px;
         background: #fff;
         h2{
             width: 100%;
            height: 52px;
            margin-bottom: 10px;
            overflow: hidden;
           display: flex;
           justify-content: space-between;
          .right_p1 {
              margin-top: 12px;
              span:nth-child(1) {
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #3388ff;
                margin-left: 21px;
                margin-right: 14px;
              }
              span:nth-child(2) {
                @include add-size1($font_size_20);
                font-weight: 500;
                color: #333333;
              }
            }
          .right_p2{
              margin-top: 12px;
              .el-button{
                color: #585858;
                @include add-size($font_size_16);
                margin-right: 20px;
              }
          }
         }
         .StatisticalOverview_right_2_table{
           width: 90%;
           height: 278px;
           margin: 0 auto;
           background: blue;
         }
      }
    }
  }
}
/deep/.el-progress-bar__inner{
  background: linear-gradient(242deg, #3BECF2 0%, #3388FF 100%);
}
/deep/.el-input {
    width: 380px;
    @include add-size($font_size_16);
}
::-webkit-scrollbar {
  width: 6px; /*对垂直流动条有效*/
  height: 6px; /*对水平流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 3px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
}
/deep/.el-table th {// 表格标题样式
  background-color: #F0F7FD;
  @include add-size($font_size_16);
  vertical-align: middle;
  color:  #3388FF;
}
/deep/.el-table__row {//表格单元格样式
  font-weight: 400;
  @include add-size($font_size_16);
  vertical-align: middle;
}
  // 表格滚动条的宽度
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }
  // 表格滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
  }
</style>